import React from 'react';
import ReactPlayer from 'react-player';

const Home = () => {
  return (
    <div className="p-4 bg-gray-900">
      {/* Titre principal */}
      <h1 className="text-2xl font-bold text-center text-white my-8">Bienvenue sur N'JOY THE BEAT</h1>
      
      {/* Section vidéo */}
      <div className="video-container text-center">
        <div
          className="bg-gray-800 rounded-lg shadow-lg mx-auto"
          style={{
            width: '100%', // Largeur s'ajuste automatiquement
            maxWidth: '800px', // Largeur fixe sur PC
            height: 'auto', // Hauteur adaptative
            aspectRatio: '16/9', // Maintien du ratio 16:9
            padding: '20px', // Padding interne
          }}
        >
          <ReactPlayer
            url="https://www.youtube.com/watch?v=ozop8JZxNY8"
            controls
            width="100%"
            height="100%"
            className="rounded-lg overflow-hidden"
          />
        </div>
      </div>

      {/* Section explicative */}
      <div className="mt-8 p-6 bg-gray-900 text-gray-800">
        {/* Titre explicatif */}
        <h2 className="text-3xl font-bold text-center my-6 text-white">
          Découvrez N'JOY THE BEAT : Une immersion musicale unique
        </h2>

        {/* Texte descriptif */}
        <p className="text-lg text-justify leading-relaxed text-orange-500">
          <strong className="text-white">N'JOY THE BEAT</strong> est bien plus qu'une simple plateforme musicale. C'est un univers 
          vibrant dédié à la célébration de la diversité musicale, où artistes émergents, DJs talentueux et fans de musique 
          se connectent à travers des formats novateurs et engageants.
        </p>

        {/* Points clés */}
        <h3 className="text-2xl font-semibold mt-8 text-white">Pourquoi rejoindre N'JOY THE BEAT ?</h3>
        <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
          <li>
            <strong className="text-white">Une expérience immersive</strong> : Explorez des contenus live, des interviews exclusives 
            et des capsules vidéo courtes mais percutantes.
          </li>
          <li>
            <strong className="text-white">Une communauté passionnée</strong> : Rejoignez un espace où les amateurs et créateurs de musique 
            partagent leur passion.
          </li>
          <li>
            <strong className="text-white">Un regard neuf</strong> : Découvrez des perspectives uniques sur l'univers musical et plongez 
            dans les coulisses de l'industrie.
          </li>
        </ul>

        {/* Conclusion */}
        <p className="mt-8 text-lg leading-relaxed text-justify text-orange-500">
          Plongez dans <strong className="text-white">N'JOY THE BEAT</strong>, une plateforme où chaque note raconte une histoire et chaque 
          performance crée une connexion inoubliable. Rejoignez-nous pour vivre la musique autrement !
        </p>
      </div>
    </div>
  );
};

export default Home;
