import React from 'react';

const GiveMe5 = () => {
  return (
    <div id="give-me-5" className="p-6 bg-gray-900 text-gray-800">
      {/* Conteneur principal avec disposition flex */}
      <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-6 lg:space-y-0 lg:space-x-6">
        {/* Section texte */}
        <div className="flex-1">
          {/* Titre principal */}
          <h1 className="text-3xl font-bold text-left my-6 text-white">
            Découvrez “Give Me 5” : un concept unique et dynamique
          </h1>

          {/* Introduction */}
          <p className="text-lg text-justify leading-relaxed text-orange-500">
            <strong className='text-white'>Give Me 5</strong> est une expérience courte, rythmée et interactive qui vous plonge dans l’univers musical 
            de nos invités. À travers cinq questions rapides <em>“this or that”</em>, ce format divertissant offre un moment 
            authentique et engageant. Que ce soit un DJ de nos sessions live, un membre du public, un artiste ou un beatmaker, 
            chacun partage ses inspirations et préférences musicales avec spontanéité et sincérité.
          </p>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Pourquoi “Give Me 5” ?</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Authenticité</strong> : Découvrez des réponses honnêtes et instantanées qui dévoilent la personnalité unique de nos invités.
            </li>
            <li>
              <strong className='text-white'>Accessibilité</strong> : Un format court et léger, idéal pour captiver l’audience tout en créant une connexion humaine et chaleureuse.
            </li>
            <li>
              <strong className='text-white'>Musique au cœur</strong> : Explorez les goûts, les passions et les inspirations musicales des invités, tout en découvrant de nouvelles perspectives.
            </li>
          </ul>

          {/* Le concept en action */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Le concept en action</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Cinq questions “this or that”</strong> : Des choix simples mais révélateurs, par exemple : <em>Vinyle ou streaming ? Studio ou scène ?</em>
            </li>
            <li>
              <strong className='text-white'>Invités variés</strong> : DJs, artistes, beatmakers, membres du public — chaque épisode propose une diversité rafraîchissante.
            </li>
            <li>
              <strong className='text-white'>Une ambiance fun et légère</strong> : Tout est conçu pour un moment de partage et de découverte sans prise de tête.
            </li>
          </ul>
        </div>

        {/* Image */}
        <div className="flex-shrink-0">
          <img
            src="giveme5.jpg"
            alt="Give Me 5"
            className="w-full max-w-xs lg:max-w-md object-cover rounded-lg"
          />
        </div>
      </div>

      {/* Conclusion */}
      <p className="mt-8 text-lg leading-relaxed text-justify text-orange-500">
        Plongez dans <strong className='text-white'>Give Me 5</strong> et laissez-vous surprendre par les personnalités et les préférences musicales qui façonnent l’univers 
        de vos artistes et créateurs favoris. Un rendez-vous incontournable pour tous les passionnés de musique !
      </p>
    </div>
  );
};

export default GiveMe5;
