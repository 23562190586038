import React from 'react';

const LiveSessions = () => {
  return (
    <div id="live-sessions" className="p-6 text-gray-800 bg-gray-900">
      {/* Conteneur principal avec disposition flex */}
      <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-6 lg:space-y-0 lg:space-x-6">
        {/* Section texte */}
        <div className="flex-1">
          {/* Titre principal */}
          <h1 className="text-3xl font-bold text-left my-6 text-white">
            N’joy the Beat x Live Sessions : Vivez la musique au plus près
          </h1>

          {/* Introduction */}
          <p className="text-lg text-justify leading-relaxed text-orange-500">
            <strong className='text-white'>N’joy the Beat x Live Sessions</strong> est une série de performances musicales uniques qui capture 
            l’essence même de la musique live. Inspiré par l’énergie vibrante de N’joy, ce concept rapproche les fans 
            de musique en leur offrant une expérience immersive et inédite.
          </p>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Un concept unique :</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Des DJ de tous horizons</strong> : Chaque session met en lumière des talents variés, allant des artistes 
              confirmés aux nouvelles étoiles montantes, pour une diversité musicale riche et passionnante.
            </li>
            <li>
              <strong className='text-white'>Performances en live</strong> : Contrairement aux enregistrements traditionnels, ces sessions capturent 
              l’énergie brute et l’émotion d’un moment unique partagé entre le DJ et son public.
            </li>
            <li>
              <strong className='text-white'>Une immersion totale</strong> : Chaque session est filmée et photographiée avec soin, créant des contenus 
              dynamiques pour Instagram et TikTok qui transportent les spectateurs au cœur de l’action.
            </li>
          </ul>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Pourquoi suivre N’joy the Beat ?</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Une plateforme pour les talents</strong> : Les DJ émergents trouvent ici une scène pour se faire connaître 
              et partager leur passion avec un public enthousiaste.
            </li>
            <li>
              <strong className='text-white'>Une connexion authentique</strong> : Découvrez la magie d’une véritable interaction entre les artistes et leur 
              public, capturée sans filtres ni artifices.
            </li>
            <li>
              <strong className='text-white'>Une célébration de la musique vivante</strong> : Spontanée, vibrante et pleine d’émotions, chaque performance 
              reflète la musique dans sa forme la plus pure.
            </li>
          </ul>
        </div>

        {/* Image */}
        <div className="flex-shrink-0">
          <img
            src="LiveSession.png"
            alt="N’joy the Beat Live Session"
            className="w-full max-w-xs lg:max-w-md object-cover rounded-lg"
          />
        </div>
        </div>

      {/* Conclusion */}
      <p className="mt-8 text-lg leading-relaxed text-justify text-orange-500">
        Rejoignez <strong className='text-white'>N’joy the Beat x Live Sessions</strong> et plongez dans un univers où chaque note, chaque vibration et chaque 
        instant vous rapprochent un peu plus de la musique que vous aimez. À vivre, partager et ressentir sur Instagram et TikTok !
      </p>
    </div>
  );
};

export default LiveSessions;
