import React from 'react';

const BeatStories = () => {
  return (
    <div id="beat-stories" className="p-6 text-gray-800 bg-gray-900">
      {/* Conteneur principal avec disposition flex */}
      <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-6 lg:space-y-0 lg:space-x-6">
        {/* Section texte */}
        <div className="flex-1">
          {/* Titre principal */}
          <h1 className="text-3xl font-bold text-left my-6 text-white">
            Beat Stories : L’univers musical en moins de deux minutes
          </h1>

          {/* Introduction */}
          <p className="text-lg text-justify leading-relaxed text-orange-500">
            <strong className='text-white'>Beat Stories</strong> est un concept court et percutant qui explore l’univers de la musique de manière 
            éducative et divertissante. Chaque épisode, d’une durée de moins de deux minutes, vous emmène à la découverte 
            des multiples facettes de la musique, des icônes légendaires aux dernières tendances.
          </p>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Un format concis et captivant :</h2>
          <ul className="list-disc list-inside mt-4 text-orange-500">
            <li>
              <strong className='text-white'>Des thématiques variées</strong> : Découvrez l’histoire des genres musicaux, les artistes influents et 
              les morceaux emblématiques qui ont marqué l’industrie.
            </li>
            <li>
              <strong className='text-white'>L’actualité musicale</strong> : Restez informé des dernières tendances, des sorties d’albums et des événements 
              marquants de la scène musicale.
            </li>
            <li>
              <strong className='text-white'>Une approche éducative</strong> : Chaque épisode enrichit votre culture musicale tout en restant accessible 
              et dynamique.
            </li>
          </ul>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Pourquoi suivre Beat Stories ?</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Une immersion rapide</strong> : En moins de deux minutes, plongez dans un contenu riche et varié, idéal 
              pour les passionnés de musique pressés.
            </li>
            <li>
              <strong className='text-white'>Un mélange de passé et de présent</strong> : De l’histoire de la musique aux nouveautés incontournables, 
              Beat Stories relie les époques et les influences.
            </li>
            <li>
              <strong className='text-white'>Accessible à tous</strong> : Que vous soyez novice ou expert, ces capsules courtes et engageantes 
              s’adressent à toutes les générations.
            </li>
          </ul>
        </div>

        {/* Image */}
        <div className="flex-shrink-0">
          <img
            src="story.png"
            alt="Beat Stories"
            className="w-full max-w-xs lg:max-w-md object-cover rounded-lg"
          />
        </div>
      </div>

      {/* Conclusion */}
      <p className="mt-8 text-lg leading-relaxed text-justify text-orange-500">
        Rejoignez <strong className='text-white'>Beat Stories</strong> pour une dose quotidienne de musique, à consommer en quelques instants mais 
        à savourer longtemps. Une manière rapide et fun de vivre et comprendre la musique !
      </p>
    </div>
  );
};

export default BeatStories;
