import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './Pages/Home';
import LiveSessions from './Pages/LiveSessions';
import InsideTheBeat from './Pages/InsideTheBeat';
import BeatStories from './Pages/BeatStories';
import GiveMe5 from './Pages/GiveMe5';
import PointOfView from './Pages/PointOfView';

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-900">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/live-sessions" element={<LiveSessions />} />
            <Route path="/inside-the-beat" element={<InsideTheBeat />} />
            <Route path="/beat-stories" element={<BeatStories />} />
            <Route path="/give-me-5" element={<GiveMe5 />} />
            <Route path="/point-of-view" element={<PointOfView />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
