import React from 'react';

const InsideTheBeat = () => {
  return (
    <div id="inside-the-beat" className="p-6 text-gray-800 bg-gray-900">
      {/* Conteneur principal avec disposition flex */}
      <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-6 lg:space-y-0 lg:space-x-6">
        {/* Section texte */}
        <div className="flex-1">
          {/* Titre principal */}
          <h1 className="text-3xl font-bold text-left my-6 text-white">
            Inside the Beat : Au cœur de l’industrie musicale
          </h1>

          {/* Introduction */}
          <p className="text-lg text-justify leading-relaxed text-orange-500">
            <strong className='text-white'>Inside the Beat</strong> est une série d’interviews exclusives qui vous plonge dans les coulisses de 
            l’industrie musicale. Ce concept innovant, en continuité avec <strong className='text-white'>N’joy the Beat</strong>, met en lumière les 
            différents acteurs qui façonnent la musique que nous écoutons et aimons.
          </p>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Découvrez l’envers du décor :</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Des rencontres exclusives</strong> : Producteurs, beatmakers, labels, managers, ingénieurs du son et 
              artistes partagent leurs histoires, leurs défis et leurs inspirations.
            </li>
            <li>
              <strong className='text-white'>Un voyage dans la création musicale</strong> : Explorez chaque étape, des premières notes à la production 
              finale, en passant par les choix artistiques et les stratégies de diffusion.
            </li>
            <li>
              <strong className='text-white'>Des récits authentiques</strong> : Chaque interview offre une immersion unique dans les réalités, les 
              passions et les ambitions des acteurs de l’industrie musicale.
            </li>
          </ul>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Pourquoi regarder Inside the Beat ?</h2>
          <ul className="list-disc list-inside mt-4 space-y-2 text-orange-500">
            <li>
              <strong className='text-white'>Un regard inédit</strong> : Découvrez les coulisses de l’industrie, ses rouages complexes, et les personnes 
              qui transforment une idée en musique.
            </li>
            <li>
              <strong className='text-white'>Des histoires inspirantes</strong> : Chaque invité partage son parcours et ses apprentissages, offrant une 
              perspective enrichissante pour les fans de musique et les aspirants professionnels.
            </li>
            <li>
              <strong className='text-white'>Une immersion totale</strong> : Que vous soyez un passionné de musique ou simplement curieux, Inside the Beat 
              vous transporte au cœur des processus créatifs et des stratégies qui donnent vie aux morceaux que vous aimez.
            </li>
          </ul>
        </div>

        {/* Image */}
        <div className="flex-shrink-0">
          <img
            src="InsideBeat.png"
            alt="Inside the Beat"
            className="w-full max-w-xs lg:max-w-md object-cover rounded-lg"
          />
        </div>
      </div>

      {/* Conclusion */}
      <p className="mt-8 text-lg leading-relaxed text-justify text-orange-500">
        Rejoignez <strong className='text-white'>Inside the Beat</strong> pour une exploration fascinante de l’industrie musicale, et découvrez les 
        secrets, les défis et les triomphes de ceux qui vivent et respirent musique. Entrez dans les coulisses et laissez-vous 
        inspirer !
      </p>
    </div>
  );
};

export default InsideTheBeat;
