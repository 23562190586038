import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <footer className="flex justify-center space-x-6 bg-gray-800 text-white py-4">
      <a href="https://youtube.com/@njoy.belgium?si=d5mL8VRf29wN-1Ua" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube text-2xl"></i>
      </a>
      <a href="https://www.instagram.com/njoy.be?igsh=MTVzMGYzamZ5ZGwwag==" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram text-2xl"></i>
      </a>
      <a href="https://www.tiktok.com/@njoy.be?_t=ZG-8sCwfiqMhwP&_r=1" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-tiktok text-2xl"></i>
      </a>
      <a href="https://x.com/njoy_be?s=21&t=kTwzaXyJk3LtT29kG-LPEw" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter text-2xl"></i>
      </a>
      <a href="https://www.facebook.com/share/oD8hhEzmDkWVjx2r/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-facebook text-2xl"></i>
      </a>
    </footer>
  );
};

export default Footer;
